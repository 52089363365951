import * as i0 from '@angular/core';
import { EventEmitter, ElementRef, Component, ViewEncapsulation, ChangeDetectionStrategy, Input, Output, ViewChildren, NgModule } from '@angular/core';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import { ValueAccessorBase, numberKeyCodes, KeyCode, CapturumSharedModule } from '@capturum/ui/api';
import * as i1 from '@angular/forms';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import * as i3 from '@capturum/ui/input';
import { CapturumInputComponent, CapturumInputModule } from '@capturum/ui/input';
import { Subscription, fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';
import { KeyFilterModule } from 'primeng/keyfilter';
function CapturumInputCodeComponent_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementContainerStart(0)(1, 2);
    i0.ɵɵelementStart(2, "cap-input", 3);
    i0.ɵɵlistener("paste", function CapturumInputCodeComponent_ng_container_1_Template_cap_input_paste_2_listener($event) {
      const index_r2 = i0.ɵɵrestoreView(_r1).index;
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.onPaste($event, index_r2));
    });
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd()();
  }
  if (rf & 2) {
    const control_r4 = ctx.$implicit;
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("formControl", control_r4)("filter", "pint");
  }
}
class CapturumInputCodeComponent extends ValueAccessorBase {
  get domInputs() {
    return this._domInputs.toArray();
  }
  get codeInputArray() {
    return this.formGroup.get('code');
  }
  constructor(formBuilder, cdr) {
    super();
    this.formBuilder = formBuilder;
    this.cdr = cdr;
    this.FormControl = FormControl;
    this.subscriptions = new Subscription();
    this.maxInputLength = 6;
    this.valuesFilled = new EventEmitter();
    this.onInitialized = new EventEmitter();
  }
  ngOnInit() {
    this.createForm();
    this.handleEmitWhenCodeIsEntered();
    this.handleKeyboardInput();
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
  ngAfterViewInit() {
    this.focusInput(0);
  }
  focus() {
    this.focusInput(0);
  }
  reset() {
    this.formGroup.reset();
    this.codeInputArray.controls.forEach((control, index) => {
      if (index !== 0) {
        control.disable();
      }
    });
    this.focusInput(0);
  }
  writeValue(value) {
    if (value) {
      super.writeValue(value);
    } else {
      this.codeInputArray.reset();
    }
  }
  onPaste(event, inputIndex) {
    event.preventDefault();
    event.stopPropagation();
    const data = event?.clipboardData?.getData('text')?.trim() ?? null;
    if (this.isEmptyValue(data)) {
      return;
    }
    const values = data.split('').slice(0, this.maxInputLength);
    let inputValueIndex = 0;
    for (let index = inputIndex; index < this.domInputs.length; index++) {
      if (inputValueIndex === values.length) {
        break;
      }
      const currentValue = values[inputValueIndex].toString();
      this.codeInputArray.at(index).enable();
      this.codeInputArray.at(index).patchValue(this.isEmptyValue(currentValue) ? '' : currentValue);
      inputValueIndex++;
    }
    this.focusInput(values.length - 1);
  }
  createForm() {
    this.formGroup = this.formBuilder.group({
      code: this.formBuilder.array([])
    });
    for (let i = 0; i < this.maxInputLength; i++) {
      const isNotFirstItem = i > 0;
      const control = new FormControl({
        value: null,
        disabled: isNotFirstItem
      }, [Validators.required]);
      this.codeInputArray.push(control);
    }
  }
  handleEmitWhenCodeIsEntered() {
    this.subscriptions.add(this.codeInputArray.valueChanges.subscribe(() => {
      this.value = this.codeInputArray.value.join('');
      if (this.codeInputArray.valid) {
        this.valuesFilled.emit();
      }
    }));
  }
  handleKeyboardInput() {
    this.subscriptions.add(fromEvent(document, 'keyup').pipe(map(keyEvent => keyEvent.code)).subscribe(keyCode => {
      const values = this.formGroup.getRawValue();
      const firstAvailableIndex = values.code.findIndex(value => !value);
      const focusedIndex = this.getIndexWithinRange(firstAvailableIndex);
      this.handleNumericKeyPressed(keyCode, focusedIndex);
      this.handleBackspaceKeyPressed(keyCode, focusedIndex, values[focusedIndex]);
    }));
  }
  getIndexWithinRange(index) {
    if (index === -1) {
      return this.maxInputLength - 1;
    } else if (index === 0) {
      return 0;
    } else {
      return index - 1;
    }
  }
  handleNumericKeyPressed(keyCode, focusedIndex) {
    if (numberKeyCodes.includes(keyCode)) {
      if (focusedIndex + 1 < this.maxInputLength) {
        this.enableInput(focusedIndex + 1);
        this.focusInput(focusedIndex + 1);
      }
    }
  }
  handleBackspaceKeyPressed(keyCode, focusedIndex, focusedValue) {
    if (keyCode === KeyCode.Backspace) {
      if (this.isEmptyValue(focusedValue)) {
        this.codeInputArray.at(focusedIndex).reset();
        this.disableInputStartingFrom(focusedIndex + 1);
        this.focusInput(focusedIndex);
      }
    }
  }
  focusInput(index) {
    setTimeout(() => {
      this.domInputs[index]?.nativeElement?.querySelector('input')?.focus();
      this.cdr.detectChanges();
    });
  }
  enableInput(index) {
    this.codeInputArray.at(index).enable();
    this.cdr.detectChanges();
  }
  disableInputStartingFrom(startingIndex) {
    this.codeInputArray.controls.slice(startingIndex, this.codeInputArray.controls.length).forEach(control => {
      control.disable();
      control.reset();
    });
    this.cdr.detectChanges();
  }
  isEmptyValue(value) {
    return !value || !value.toString().length;
  }
  static {
    this.ɵfac = function CapturumInputCodeComponent_Factory(t) {
      return new (t || CapturumInputCodeComponent)(i0.ɵɵdirectiveInject(i1.FormBuilder), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CapturumInputCodeComponent,
      selectors: [["cap-input-code"]],
      viewQuery: function CapturumInputCodeComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(CapturumInputComponent, 5, ElementRef);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._domInputs = _t);
        }
      },
      inputs: {
        maxInputLength: "maxInputLength"
      },
      outputs: {
        valuesFilled: "valuesFilled",
        onInitialized: "onInitialized"
      },
      features: [i0.ɵɵProvidersFeature([ValueAccessorBase.getProviderConfig(CapturumInputCodeComponent)]), i0.ɵɵInheritDefinitionFeature],
      decls: 2,
      vars: 2,
      consts: [[1, "inputs-code", "w-100", 3, "formGroup"], [4, "ngFor", "ngForOf"], ["formArrayName", "code"], ["maxLength", "1", "styleClass", "input-code", 3, "paste", "formControl", "filter"]],
      template: function CapturumInputCodeComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "form", 0);
          i0.ɵɵtemplate(1, CapturumInputCodeComponent_ng_container_1_Template, 3, 2, "ng-container", 1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("formGroup", ctx.formGroup);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngForOf", ctx.codeInputArray == null ? null : ctx.codeInputArray.controls);
        }
      },
      dependencies: [i2.NgForOf, i1.ɵNgNoValidate, i1.NgControlStatus, i1.NgControlStatusGroup, i3.CapturumInputComponent, i1.FormControlDirective, i1.FormGroupDirective, i1.FormArrayName],
      styles: [".inputs-code{display:flex}.inputs-code cap-input{padding:0 .5rem}.inputs-code cap-input:first-child{padding-left:0}.inputs-code cap-input cap-validator{display:none}.inputs-code cap-input .em-input{text-align:center}body .inputs-code cap-input .p-inputgroup input.p-inputtext,body .inputs-code cap-input .p-inputgroup input.p-inputtext:hover,body .inputs-code cap-input .p-inputgroup .p-inputtext:enabled:hover:not(.p-state-error),body .inputs-code cap-input .p-inputgroup .p-inputtext:enabled:focus:not(.p-state-error){padding:.43rem}body .inputs-code cap-input .p-inputgroup input[disabled]{background-color:#efefef}\n"],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CapturumInputCodeComponent, [{
    type: Component,
    args: [{
      selector: 'cap-input-code',
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [ValueAccessorBase.getProviderConfig(CapturumInputCodeComponent)],
      template: "<form class=\"inputs-code w-100\" [formGroup]=\"formGroup\">\n  <ng-container *ngFor=\"let control of codeInputArray?.controls; let index = index\">\n    <ng-container formArrayName=\"code\">\n      <cap-input\n        [formControl]=\"control\"\n        maxLength=\"1\"\n        [filter]=\"'pint'\"\n        styleClass=\"input-code\"\n        (paste)=\"onPaste($event, index)\">\n      </cap-input>\n    </ng-container>\n  </ng-container>\n</form>\n",
      styles: [".inputs-code{display:flex}.inputs-code cap-input{padding:0 .5rem}.inputs-code cap-input:first-child{padding-left:0}.inputs-code cap-input cap-validator{display:none}.inputs-code cap-input .em-input{text-align:center}body .inputs-code cap-input .p-inputgroup input.p-inputtext,body .inputs-code cap-input .p-inputgroup input.p-inputtext:hover,body .inputs-code cap-input .p-inputgroup .p-inputtext:enabled:hover:not(.p-state-error),body .inputs-code cap-input .p-inputgroup .p-inputtext:enabled:focus:not(.p-state-error){padding:.43rem}body .inputs-code cap-input .p-inputgroup input[disabled]{background-color:#efefef}\n"]
    }]
  }], () => [{
    type: i1.FormBuilder
  }, {
    type: i0.ChangeDetectorRef
  }], {
    maxInputLength: [{
      type: Input
    }],
    valuesFilled: [{
      type: Output
    }],
    onInitialized: [{
      type: Output
    }],
    _domInputs: [{
      type: ViewChildren,
      args: [CapturumInputComponent, {
        read: ElementRef
      }]
    }]
  });
})();
class CapturumInputCodeModule {
  static {
    this.ɵfac = function CapturumInputCodeModule_Factory(t) {
      return new (t || CapturumInputCodeModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CapturumInputCodeModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, FormsModule, CapturumSharedModule, CapturumInputModule, ReactiveFormsModule, KeyFilterModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CapturumInputCodeModule, [{
    type: NgModule,
    args: [{
      declarations: [CapturumInputCodeComponent],
      exports: [CapturumInputCodeComponent],
      imports: [CommonModule, FormsModule, CapturumSharedModule, CapturumInputModule, ReactiveFormsModule, KeyFilterModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CapturumInputCodeComponent, CapturumInputCodeModule };
